// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    api: "https://api.educafrancodarocha.com.br/api/",
    aulas: "https://api.educafrancodarocha.com.br/",
    apiCms: "https://cms.multfacil.com.br/api/",
    team: "francodarocha",
    nometeam: "Franco da Rocha",
    nome_extenso: "App Franco da Rocha",
    theme: "blue",
    rn_login: "",
    rn_cadastro: "",
    senderID: "",
    app_id: "com.educafrancodarocha.alunos",
    app_name: "Educa Franco Portal do Aluno",
    app_description: "Aplicativo de Franco da Rocha.",
    app_url_applestore: "",
    app_url_googleplay: "",
    author_name: "Multifacil Soluções em Tecnologia",
    author_email: "",
    author_webSite: "",
    google_maps_key: "",
    politicaPrivacidade: "",
    versao: "1.2.3",
    bundle_id_ios: "",
    app_id_ios: "",
    idTeam: 1,
    assetImgUri: "francodarocha",
    mensagemOrientacaoLogin: "Se você tinha acesso ao app antigo, não será preciso fazer um <strong>novo cadastro.</strong>",
    keystorePath: "fr-app-portal-aluno-keystore.jks",
    keystoreAlias: "key()",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
